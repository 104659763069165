<template>
  <div class="home-carousel-slider">
    <div v-if="heroes && heroes.length" class="home-carousel-slider__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in heroes" :key="i">
            <router-link :to="{ name: 'hero', params: { id: item.id } }" class="home-carousel-slider__item">
              <ImgComponent width="320" height="320" :img="item.img" class="home-carousel-slider__item-img" />
            </router-link>
          </div>
        </div>
      </div>
      <SliderArrowsComponent
        v-if="swiper"
        :isBeginning="swiper.isBeginning"
        :isEnd="swiper.isEnd"
        @left="swiper.slidePrev()"
        @right="swiper.slideNext()"
      />
    </div>
    <div class="container-padding">
      <div v-if="activeItem" class="home-carousel-slider__item-info">
        <span v-if="activeItem.title" class="home-carousel-slider__item-title">{{ activeItem.title }}</span>
        <span v-if="activeItem.rank" class="home-carousel-slider__item-rank">{{ activeItem.rank }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import HEROES_CAROUSEL_SLIDER from "@/graphql/queries/heroes_carousel_slider.graphql";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "HomeCarouselSlider",
  data() {
    return {
      loading: false,
      heroes: [],
      activeHeroes: [], // активный массив, который выводится в слайдере (incoming...)
      first: 30,
      page: 1,
      swiper: null,
      activeItem: null,
      activeIndex: 15,
      hasMorePages: true, // есть ли еще страницы пагинации
      paginationTypes: {
        before: 1,
        after: 2,
      },
    };
  },
  async mounted() {
    const { data } = await this.get();
    this.heroes = data.heroes_paginate.data || [];
    this.activeItem = this.heroes[this.activeIndex];
    this.initializeSwiper();
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  watch: {
    heroes() {
      this.$nextTick(() => {
        this.updateSwiper(); // Вызываем метод обновления слайдера после изменения массива items
      });
    },
  },
  methods: {
    initializeSwiper() {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, {
          // speed: 1000,
          initialSlide: this.activeIndex,
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          effect: "coverflow",
          coverflowEffect: {
            rotate: 0,
            stretch: 21,
            depth: 110,
            modifier: 1,
            slideShadows: false,
          },
          on: {
            slideChange: this.slideChange,
            slideChangeTransitionEnd: this.slideEndHandle,
            touchEnd: this.slideEndHandle,
          },
        });
      });
    },
    updateSwiper() {
      if (this.swiper) {
        this.swiper.update(); // Обновляем слайдер
      }
    },
    slideChange() {
      if (this.swiper) {
        this.activeIndex = this.swiper.activeIndex;
        this.activeItem = this.heroes[this.swiper.activeIndex];
      }
    },
    // конец движения слайдера
    slideEndHandle() {
      if (this.swiper) {
        if (this.loading) {
          return;
        }
        const threshold = 10; // порог после которого идет подгрузка
        if (
          this.swiper.slides.length - this.swiper.activeIndex <= threshold &&
          this.swiper.activeIndex > this.swiper.previousIndex
        ) {
          this.loading = true;
          this.paginate(this.paginationTypes.after);
        } else if (
          this.swiper.activeIndex <= threshold &&
          this.swiper.activeIndex < this.swiper.previousIndex
        ) {
          this.loading = true;
          this.paginate(this.paginationTypes.before);
        }
      }
    },
    paginate(type) {
      // если мы на последней странице пагинации
      // то добавляем элементы с конца массива в начало или наоборот
      if (!this.hasMorePages && this.heroes?.length > this.first) {
        const direction = type === this.paginationTypes.before ? "toStart" : "toEnd";
        this.$nextTick(() => {
          this.rotateArray(this.heroes, 30, direction);
        });
        this.loading = false;
        return;
      }
      this.page += 1;
      this.get()
        .then(({ data }) => {
          if (type === this.paginationTypes.before) {
            this.swiper.activeIndex += data.heroes_paginate?.paginatorInfo?.count;
            this.heroes.unshift(...(data.heroes_paginate.data || []));
          } else {
            this.heroes.push(...(data.heroes_paginate.data || []));
          }
          this.hasMorePages = data.heroes_paginate?.paginatorInfo?.hasMorePages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async get() {
      return await this.$apollo.provider.defaultClient.query({
        query: HEROES_CAROUSEL_SLIDER,
        variables: {
          first: this.first,
          page: this.page,
        },
      });
    },
    rotateArray(arr, steps, direction) {
      const len = arr.length;
      if (len === 0 || steps % len === 0) {
        return arr;
      }

      // Normalize steps in case it's larger than the array length
      steps = steps % len;
      if (direction === "toStart") {
        // Take elements from the end and move them to the beginning
        this.swiper.activeIndex += steps;
        this.heroes = arr.slice(-steps).concat(arr.slice(0, len - steps));
        // this.heroes.unshift(...arr.slice(-steps));
      } else if (direction === "toEnd") {
        // Take elements from the start and move them to the end
        this.swiper.activeIndex -= steps;
        this.heroes = arr.slice(steps).concat(arr.slice(0, steps));
        // this.heroes.push(...arr.slice(steps));
      }
    },
  },
  components: { SliderArrowsComponent, ImgComponent },
};
</script>

<style lang="stylus">
.home-carousel-slider {
  display flex
  flex-direction column
  margin-bottom 70px
  +below(428px) {
    margin-bottom 25px
  }

  &__slides {
    position relative
    height 100%
    margin-bottom 15px
    +below(428px) {
      margin-bottom 10px
    }
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    height: 320px;
    +below(428px) {
      width: 180px;
      height: 180px;
    }

		&::after {
			content: ""
			display block
			position absolute
			left 0
			right 0
			top 0
			bottom 0
			z-index: 1;
			border-radius var(--big-radius)
			background var(--black)
			opacity 0.5
			transition background var(--transition)
			pointer-events none
		}
  }

	.swiper-slide-active {
		&::after {
			background transparent
		}
	}

  .slider-arrows {
    +below(768px) {
      display none
    }

    &__item {
      width 40px
      height 40px
      background transparent
      border-color var(--white)

      &:not([disabled]):hover {
        background var(--white)
        border-color var(--white)

        .icon svg path {
          transition .3s
          fill var(--black)
        }
      }

      &--left {
        left 160px
        +below(1024px) {
          left 60px
        }
      }

      &--right {
        right 160px
        +below(1024px) {
          right 60px
        }
      }
    }

    .icon {
      width 20px
      height 20px

      svg path {
        transition .3s
        fill var(--white)
      }
    }
  }

  &__item {
    width 100%
    height 100%
  }

  &__item-info {
    display flex
    flex-direction column
    align-items center
    gap 15px
    //margin-bottom 70px
    text-align center
    height 125px
    max-height 125px
    +below(428px) {
      gap 5px
      //margin-bottom 25px
    }
  }

  &__item-img {
    display block
    width 100%
    height 100%
    border-radius var(--big-radius)
  }

  &__item-title {
    font-size 1.375rem
    font-weight 700
    line-height 32px
    max-width 320px
    +below(428px) {
      font-size .875rem
      line-height 22px
      max-width 180px
    }
  }

  &__item-rank {
    font-size 1rem
    line-height 26px
    display -webkit-box
    overflow hidden
    -webkit-box-orient vertical
    -webkit-line-clamp 3
    line-clamp 3
    max-width 320px
    +below(428px) {
      font-size .875rem
      line-height 22px
      max-width 180px
    }
  }
}
</style>
